import React, { FC } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import { EMPTY_EVENT_PRODUCT } from 'datalayer-service/src/event-objects';
import cls from 'classnames';
import { User } from '../../../types/user';
import { Deal, DealCouponPosthogFeatureFlagVariantType, DealCouponVariantType, DealItem } from '../../../types/deal';
import { NewPromotion, PromotionType } from '../../../types/promotion';
import { getProductEventDataWithMattressHierarchy } from '../../../utils/dataLayer';
import { getPromotionsFromDeal, openCashbackOutboundUrl } from '../../../utils/promos';
import PromoButton from '../../atoms/PromoButton';
import NewPromoButton from '../../atoms/NewPromoButton';
import styles from './DealCoupon.module.scss';
import Button from '../../atoms/Button';
import { ROUTES } from '../../../constants/commons';
import PostHogService from '../../../utils/posthogService';
import { PostHogExperimentEvents } from '../../../constants/feature-flags';

export const DEAL_COUPON_ID = 'deal-coupon';

export enum DealCouponLayoutType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export const goToCashback = (rebate: NewPromotion, deal?: Deal, dealHash?: string): void => {
  const { protocol, host, pathname } = window.location;

  let url = `${protocol}//${host}${ROUTES.cashbackCode}${rebate.id}/?prev=${pathname}`;
  if (deal?.brand.id) url += `&manufacturer=${deal.brand.id}`;
  if (dealHash) url += `&deal=${dealHash}`;

  window.location.href = url;
};

export const handleDealClick = (deal: Deal, currentDeal: DealItem, onSeeDeal?: (deal: Deal) => void): void => {
  if (currentDeal) {
    const { onlinePromotion, rebatePromotion } = getPromotionsFromDeal(currentDeal.promotions);

    if (currentDeal.main_promotion_type === PromotionType.AFFILIATE_REBATE && deal) {
      if (rebatePromotion) {
        openCashbackOutboundUrl(deal.brand.id, rebatePromotion, onlinePromotion);
      }
    } else if (currentDeal.main_promotion_type === PromotionType.LOCAL_REBATE && rebatePromotion) {
      goToCashback(rebatePromotion, deal, currentDeal.hash);
    } else if (onSeeDeal && deal) onSeeDeal(deal);
  }
};

export type DealCouponProps = {
  pageRegion: PageRegion;
  user: User | null;
  className?: string;
  layout?: DealCouponLayoutType;
  variant?: DealCouponVariantType;
  // Only required if allowNewPromoBtnPostHogFeatureFlag is True.
  posthogVariant?: DealCouponPosthogFeatureFlagVariantType;
  deal?: Deal;
  isFetching?: boolean;
  showHeader?: boolean; // hide title and subtitle
  showExtraCashbackText?: boolean;
  inlinePromotionText?: boolean;
  hideSeeDeal?: boolean;
  allowNewPromoBtnPostHogFeatureFlag?: boolean;
  hideNewPromoBtnSubtitle?: boolean;
  onSeeDeal?: (deal: Deal) => void;
};

const getTitle = (currentDeal?: DealItem): string => {
  if (!currentDeal) return '';

  return currentDeal.retailer.name;
};

const DealCoupon: FC<DealCouponProps> = ({
  pageRegion,
  user,
  className = '',
  layout = DealCouponLayoutType.HORIZONTAL,
  variant = DealCouponVariantType.DEFAULT,
  posthogVariant = DealCouponPosthogFeatureFlagVariantType.CONTROL,
  deal,
  isFetching = false,
  showHeader = true,
  showExtraCashbackText = true,
  inlinePromotionText = false,
  hideSeeDeal = false,
  allowNewPromoBtnPostHogFeatureFlag = false,
  hideNewPromoBtnSubtitle,
  onSeeDeal,
}) => {
  const posthog = new PostHogService(user as User);
  const currentDeal = isFetching ? undefined : deal?.deals[0];
  const promotionType = currentDeal ? currentDeal.main_promotion_type : undefined;
  const isAffiliateRebate = promotionType === PromotionType.AFFILIATE_REBATE;
  const isLocalRebate = promotionType === PromotionType.LOCAL_REBATE;
  const isBrandOrRetailerPromotion =
    currentDeal &&
    (currentDeal.main_promotion_type === PromotionType.BRAND ||
      currentDeal.main_promotion_type === PromotionType.RETAILER);
  const hasMultiplePromotions = currentDeal ? currentDeal.promotions.length > 1 : false;
  const noSeeDealBtn = isBrandOrRetailerPromotion && hideSeeDeal;
  const title = getTitle(currentDeal);
  const ctaData = {
    category: hasMultiplePromotions ? CTAClickCategory.CASHBACK_PROMOTION : CTAClickCategory.ONLINE_PROMOTION,
    level: CTAClickLevel.PRIMARY,
    url: '/',
    pageRegion,
    product:
      currentDeal && currentDeal.brand
        ? getProductEventDataWithMattressHierarchy(currentDeal.brand)
        : EMPTY_EVENT_PRODUCT,
  };

  const getPromotionText = (): JSX.Element => {
    return (
      <ReactPlaceholder
        type="rect"
        style={{
          width: layout === DealCouponLayoutType.HORIZONTAL ? '70%' : '80%',
          height: '100px',
          marginBottom: '10px',
        }}
        ready={!isFetching}
      >
        <div className={styles.promotions}>
          {/* MULTIPLE PROMOTIONS AND IT IS ONLINE, BRAND OR RETAILER PROMOTION */}
          {currentDeal &&
            hasMultiplePromotions &&
            (currentDeal.promotions[1].promotion_type === PromotionType.ONLINE ||
              currentDeal.promotions[1].promotion_type === PromotionType.BRAND ||
              currentDeal.promotions[1].promotion_type === PromotionType.RETAILER) && (
              <b>{currentDeal.promotions[1].title_short}</b>
            )}

          {/* DEAL MAIN PROMOTION TYPE IS BRAND OR RETAILER */}
          {!hasMultiplePromotions && isBrandOrRetailerPromotion && <b>{currentDeal.promotions[0].title_short}</b>}

          {!inlinePromotionText && <br />}

          {hasMultiplePromotions && ' + '}

          {/* DEAL MAIN PROMOTION TYPE IS LOCAL REBATE OR AFFILIATE REBATE */}
          {currentDeal && (isAffiliateRebate || isLocalRebate) && (
            <>
              <b>{currentDeal.promotions[0].title_short} </b>
              {!inlinePromotionText && <br />}
              <span className={styles.promotionsSmallText}>from GoodBed </span>
            </>
          )}
        </div>
      </ReactPlaceholder>
    );
  };

  const handlePromoBtnClick = (): void => {
    if (allowNewPromoBtnPostHogFeatureFlag) posthog.capture(PostHogExperimentEvents.PROMO_BUTTON_CLICKED);
  };

  const getPromotionBtn = (): JSX.Element => {
    return (
      <ReactPlaceholder
        type="rect"
        style={{
          width: layout === DealCouponLayoutType.HORIZONTAL ? '22%' : '60%',
          height: '50px',
          marginBottom: '10px',
        }}
        ready={!isFetching}
      >
        {isBrandOrRetailerPromotion && !hideSeeDeal && (
          <Button
            className={styles.btnSeeDeal}
            ariaLabel="See Deal"
            size="lg"
            color="dark-blue"
            variant="outline-primary"
            ctaData={ctaData}
          >
            See Deal
          </Button>
        )}
        {!isBrandOrRetailerPromotion &&
          (!allowNewPromoBtnPostHogFeatureFlag ||
            (allowNewPromoBtnPostHogFeatureFlag &&
              posthogVariant === DealCouponPosthogFeatureFlagVariantType.CONTROL)) && (
            <PromoButton
              className={styles.btnPromotion}
              textClassname={styles.btnPromotionText}
              ctaData={ctaData}
              showPromoBtnPosthogTestText={allowNewPromoBtnPostHogFeatureFlag}
              onClick={handlePromoBtnClick}
            />
          )}
        {!isBrandOrRetailerPromotion &&
          currentDeal &&
          allowNewPromoBtnPostHogFeatureFlag &&
          posthogVariant === DealCouponPosthogFeatureFlagVariantType.TEST && (
            <NewPromoButton
              className={styles.btnNewPromotion}
              promotionType={currentDeal?.main_promotion_type}
              mfrName={currentDeal.brand.name}
              retailerName={currentDeal.retailer.name}
              ctaData={ctaData}
              hideSubtitle={hideNewPromoBtnSubtitle}
              onClick={handlePromoBtnClick}
            />
          )}
      </ReactPlaceholder>
    );
  };

  const getExtraCashBackText = (): JSX.Element => {
    return (
      <ReactPlaceholder type="text" style={{ marginBottom: '5px' }} rows={2} ready={!isFetching}>
        <div className={styles.extra}>
          Get an{' '}
          <u>
            <b>extra {currentDeal?.description} cash back</b>
          </u>{' '}
          from GoodBed if you buy {isAffiliateRebate ? `online from ${title}` : `at a ${title} store`}.
        </div>
      </ReactPlaceholder>
    );
  };

  const variantsMap = {
    blue: styles.blueDealCoupon,
    default: styles.defaultDealCoupon,
  };

  return (
    <a
      id={DEAL_COUPON_ID}
      data-testid={DEAL_COUPON_ID}
      className={`${styles.container} ${styles[layout] || ''} ${noSeeDealBtn ? styles.noSeeDealBtn : ''} ${className} ${
        variantsMap[variant] || ''
      }`}
      role="button"
      tabIndex={-1}
      rel="nofollow"
      onClick={
        isFetching
          ? undefined
          : (): void => {
              if (deal && currentDeal) handleDealClick(deal, currentDeal, onSeeDeal);
            }
      }
    >
      {showHeader && (
        <>
          <ReactPlaceholder type="text" style={{ width: '50%', marginBottom: '10px' }} rows={1} ready={!isFetching}>
            <div className={styles.title}>{title}</div>
          </ReactPlaceholder>
          {layout === DealCouponLayoutType.VERTICAL && (
            <ReactPlaceholder type="text" style={{ width: '30%', marginBottom: '10px' }} rows={1} ready={!isFetching}>
              <div className={styles.subtitle}>
                {isAffiliateRebate ? 'Online Seller' : null}
                {!isAffiliateRebate && currentDeal?.retailer.nearest_store ? (
                  <span>
                    {currentDeal?.retailer.nearest_store.city}, {currentDeal?.retailer.nearest_store.state_abbr}
                  </span>
                ) : null}
              </div>
            </ReactPlaceholder>
          )}
        </>
      )}
      {layout === DealCouponLayoutType.HORIZONTAL ? (
        <>
          {showExtraCashbackText && !isBrandOrRetailerPromotion && getExtraCashBackText()}
          <div
            className={cls(
              `row align-items-center no-gutters ${noSeeDealBtn ? 'justify-content-center' : ''}`,
              styles.horizontalContainer,
            )}
          >
            {getPromotionBtn()}
            {getPromotionText()}
          </div>
        </>
      ) : (
        <>
          {getPromotionText()}
          {getPromotionBtn()}
          {showExtraCashbackText && !isBrandOrRetailerPromotion && getExtraCashBackText()}
        </>
      )}
    </a>
  );
};

export default DealCoupon;
