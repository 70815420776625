import React, { FC } from 'react';
import cls from 'classnames';
import { CTAClick } from 'datalayer-service/src/types';
import PromoButton from '../PromoButton';
import NewPromoButton from '../NewPromoButton';
import { DealCouponPosthogFeatureFlagVariantType, DealItem } from '../../../types/deal';

export type PromoBoxProps = {
  title: string;
  subTitle: string;
  ribbonText?: string;
  ribbonPosition?: 'top-left' | 'top-right';
  ribbonVariant?: 'exclusive' | 'extra' | 'none';
  imageSrc?: string;
  variant?: 'solid' | 'dashed';
  // Only required if allowNewPromoBtnPostHogFeatureFlag is True.
  posthogVariant?: DealCouponPosthogFeatureFlagVariantType;
  deal?: DealItem; // required for NewPromoButton PostHog AB Test. When showNewPromoBtnPosthogTest is True.
  ctaData?: CTAClick; // required if onClick is passed.
  allowNewPromoBtnPostHogFeatureFlag?: boolean;
  onClick?(): void;
};

export const DATA_TEST_PROMO_BOX = 'promo-box-test-id';
export const DATA_TEST_PROMO_BOX_TITLE = 'promo-box-title-test-id';
export const DATA_TEST_PROMO_BOX_DESCRIPTION = 'promo-box-description-test-id';
export const DATA_TEST_PROMO_BOX_IMG = 'promo-box-img-test-id';

const PromoBox: FC<PromoBoxProps> = ({
  title,
  subTitle,
  imageSrc = '/static/images/GoodBed_logo.svg',
  ribbonPosition = 'top-left',
  ribbonVariant = 'none',
  variant = 'solid',
  posthogVariant = DealCouponPosthogFeatureFlagVariantType.CONTROL,
  deal,
  ctaData,
  allowNewPromoBtnPostHogFeatureFlag = false,
  onClick,
}) => {
  const hasRibbon = ribbonVariant !== 'none';
  return (
    <div
      className={cls(variant, 'shared-ui__promo-box', {
        'has-ribbon': hasRibbon,
        [ribbonPosition]: hasRibbon,
        [ribbonVariant]: hasRibbon,
      })}
      data-testid={DATA_TEST_PROMO_BOX}
    >
      <div className="share-ui__promo-box__image">
        <img
          className="share-ui__promo-box__image_img"
          loading="lazy"
          src={imageSrc}
          alt="Promo Box"
          data-testid={DATA_TEST_PROMO_BOX_IMG}
        />
      </div>
      <div className="share-ui__promo-box__content">
        <div className="share-ui__promo-box__title" data-testid={DATA_TEST_PROMO_BOX_TITLE}>
          {title}
        </div>
        <div
          className="share-ui__promo-box__description"
          data-testid={DATA_TEST_PROMO_BOX_DESCRIPTION}
          dangerouslySetInnerHTML={{ __html: subTitle }}
        />
        {onClick &&
          ctaData &&
          (!allowNewPromoBtnPostHogFeatureFlag ||
            (allowNewPromoBtnPostHogFeatureFlag &&
              posthogVariant === DealCouponPosthogFeatureFlagVariantType.CONTROL)) && (
            <PromoButton
              ctaData={ctaData}
              showPromoBtnPosthogTestText={allowNewPromoBtnPostHogFeatureFlag}
              onClick={onClick}
            />
          )}
        {onClick &&
          ctaData &&
          deal &&
          allowNewPromoBtnPostHogFeatureFlag &&
          posthogVariant === DealCouponPosthogFeatureFlagVariantType.TEST && (
            <NewPromoButton
              promotionType={deal.main_promotion_type}
              mfrName={deal.brand.name}
              retailerName={deal.retailer.name}
              ctaData={ctaData}
              hideSubtitle
              onClick={onClick}
            />
          )}
      </div>
    </div>
  );
};
export default PromoBox;
