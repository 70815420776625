import React, { FC, memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Modal, Row } from 'react-bootstrap';

const backdropIndexClassNames = {
  1050: 'backdrop-1050',
  1060: 'backdrop-1060',
  1070: 'backdrop-1070',
};

export const MODAL_TEST_ID = 'modal';

export type ModalSizeTypes = 'sm' | 'lg' | 'xl';

export interface ModalProps {
  className?: string;
  bodyClassName?: string;
  show: boolean;
  size?: ModalSizeTypes;
  backdrop?: 'static' | true | false;
  backdropClassName?: string;
  backdropIndex?: '1050' | '1060' | '1070';
  titleTag?: any;
  closeButton?: boolean;
  centered?: boolean;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  header?: JSX.Element | JSX.Element[];
  body?: JSX.Element | JSX.Element[];
  footer?: JSX.Element | JSX.Element[];
  onHide?: () => void;
  enforceFocus?: boolean;
  id?: string;
  testId?: string;
}

const BaseModal: FC<ModalProps> = ({
  className = '',
  bodyClassName = '',
  show,
  size = 'lg',
  backdrop = true,
  backdropIndex,
  backdropClassName = '',
  titleTag = 'h1',
  closeButton = true,
  centered = false,
  title,
  subtitle,
  header,
  body,
  footer,
  onHide = (): void => {},
  testId = MODAL_TEST_ID,
  enforceFocus = true,
  id = undefined,
}: ModalProps) => (
  <Modal
    data-testid={testId}
    className={className}
    backdropClassName={`${backdropClassName} ${backdropIndex ? backdropIndexClassNames[backdropIndex] : ''}`}
    backdrop={backdrop}
    show={show}
    size={size}
    centered={centered}
    onHide={onHide}
    enforceFocus={enforceFocus}
    id={id}
  >
    <Modal.Header closeButton={closeButton}>
      {isEmpty(header) && (
        <Row noGutters>
          <Modal.Title as={titleTag}>{title}</Modal.Title>
          <div className="modal-subtitle">{subtitle}</div>
        </Row>
      )}
      {!isEmpty(header) && header}
    </Modal.Header>
    {body && <Modal.Body className={bodyClassName}>{body}</Modal.Body>}
    {footer && <Modal.Footer>{footer}</Modal.Footer>}
  </Modal>
);

export default memo(BaseModal);
