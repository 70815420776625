'use client';

import React, { memo } from 'react';
import cls from 'classnames';
import { Button as BSButton } from 'react-bootstrap';
import DataLayerService from 'datalayer-service';
import { CTAClick } from 'datalayer-service/src/types';
import * as styles from './Button.module.scss';
import { getClassNames } from '../../../utils/styles';

export const BUTTON_TEST_ID = 'button-component';

export const GRAY = 'gray';
export const DEFAULT_TYPE = 'button';

export type ButtonTypes = 'button' | 'reset' | 'submit';
export type ButtonColorTypes = 'gray' | 'blue-light' | 'dark-blue' | 'light-green' | 'dark-green' | 'compare';
export type ButtonSizeTypes = 'sm' | 'lg';
export type ButtonVariantTypes = 'link' | 'primary' | 'secondary' | 'outline-primary' | 'outline-secondary' | 'danger';

export type ButtonProps = {
  id?: string;
  className?: string;
  type?: ButtonTypes;
  color?: ButtonColorTypes;
  size?: ButtonSizeTypes;
  variant?: ButtonVariantTypes;
  ariaLabel: string;
  children: any;
  ctaData: CTAClick | null;
  disabled?: boolean;
  loading?: boolean;
  tabIndex?: number;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
};

const Button: React.FunctionComponent<ButtonProps> = ({
  id,
  className,
  type = DEFAULT_TYPE,
  color = GRAY,
  size = 'sm',
  variant = 'primary',
  ariaLabel,
  children,
  ctaData,
  disabled = false,
  loading = false,
  tabIndex,
  onClick,
}: ButtonProps) => {
  const handleBtnClick = (e: React.MouseEvent<HTMLElement>): void => {
    if (loading) {
      e.preventDefault();
      return;
    }

    if (ctaData) {
      const dataLayerService = new DataLayerService(window);

      dataLayerService.CTAClickEvent(ctaData);

      dataLayerService.CTAClickEvent(ctaData);
    }

    if (onClick) onClick(e);
  };

  return (
    <BSButton
      id={id}
      className={cls(
        getClassNames('btn', styles),
        getClassNames(`btn-${color}`, styles),
        getClassNames(`btn-${variant}`, styles),
        className,
      )}
      size={size}
      type={type}
      variant={variant}
      aria-label={ariaLabel}
      disabled={disabled || loading}
      onClick={handleBtnClick}
      tabIndex={tabIndex}
      data-testid={BUTTON_TEST_ID}
    >
      {children}
      {loading ? (
        <div className={cls('spinner-border', getClassNames('loading', styles))} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : null}
    </BSButton>
  );
};

export default memo(Button);
