import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import DataLayerService from 'datalayer-service';
import { CashbackCodeAction } from 'datalayer-service/src/types/enums';
import { PromotionType } from 'shared-ui/src/types/promotion';
import CodePromoButton from './code';

const dataLayerService = new DataLayerService(window);
const VOWELS = ['a', 'e', 'i', 'o', 'u'];
export const ID = 'cashback-btn';

class CashbackPromoButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleCashbackModal = this.handleCashbackModal.bind(this);
    this.getCashbackPromotionText = this.getCashbackPromotionText.bind(this);
  }

  handleCashbackModal() {
    const {
      storeSlug,
      storeName,
      retailerSlug,
      retailerName,
      promotionID,
      manufacturerId,
      promoType,
      pageRegion,
      source,
    } = this.props;

    dataLayerService.retailerCashbackCodeInterationEvent({
      action: CashbackCodeAction.CLICK,
      retailerName: retailerName || retailerSlug || storeName || storeSlug,
      retailerLocation: storeSlug || retailerSlug,
      pageRegion,
      source,
      type: promoType,
    });

    let url = `/cashback-code/${promotionID}/?type=${promoType}&prev=${
      window.location.pathname + window.location.search
    }`;

    if (manufacturerId) url += `&manufacturer=${manufacturerId}`;

    if (storeSlug) {
      window.location.href = `${url}&store=${storeSlug}`;
    } else {
      window.location.href = `${url}&retailer=${retailerSlug}`;
    }
  }

  getCashbackPromotionText() {
    const { longText, promoType, retailerName, showPromotionTypeText } = this.props;

    if (longText)
      return (
        <>
          <i className="cashback-icon fa-regular fa-info-circle" />
          <span
            className="long-text"
            style={{
              fontWeight: 'normal',
              textAlign: 'left',
            }}
          >
            Plus, <b>get extra Cash Back from GoodBed</b> if you spend $599 or more.
          </span>
        </>
      );

    const firstLetter = retailerName && retailerName.length > 0 ? retailerName[0].toLowerCase() : '';

    const retailerNameText = retailerName ? `${retailerName} store` : 'store';

    const text =
      promoType === PromotionType.LOCAL_REBATE
        ? `Heading to ${VOWELS.includes(firstLetter) ? 'an' : 'a'} ${retailerNameText}?`
        : `Shopping online with ${retailerName || ''}?`;

    return (
      <b>
        {showPromotionTypeText && text}
        <br />
        Get Extra Cash Back from GoodBed!
      </b>
    );
  }

  render() {
    const { showButton, longText, showPromotionTypeText } = this.props;

    return (
      <button
        type="button"
        className={`compact-promo-box cashback-promo-btn ${
          (longText ? 'has-long-text' : '',
          showButton ? 'has-button' : '',
          showPromotionTypeText ? 'has-promo-type-text' : '')
        }`}
        // eslint-disable-next-line
        rel="nofollow"
        onClick={this.handleCashbackModal}
      >
        {this.getCashbackPromotionText()}
        {showButton ? <CodePromoButton onClick={this.handleCashbackModal} /> : null}
      </button>
    );
  }
}

CashbackPromoButton.defaultProps = {
  longText: false,
  showButton: false,
  showPromotionTypeText: false,
};

CashbackPromoButton.propTypes = {
  storeSlug: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  promotionID: PropTypes.number.isRequired,
  manufacturerId: PropTypes.number,
  retailerSlug: PropTypes.string,
  retailerName: PropTypes.string,
  locationPage: PropTypes.string,
  pageRegion: PropTypes.string,
  source: PropTypes.string,
  promoType: PropTypes.string,
  showButton: PropTypes.bool,
  longText: PropTypes.bool,
  isMobile: PropTypes.bool,
  showPromotionTypeText: PropTypes.bool,
};

export default CashbackPromoButton;

export function initCashbackPromoButtons(selector = `[id=${ID}]`) {
  const promoInstances = Array.from(document.querySelectorAll(selector));

  promoInstances.forEach((element) => {
    const storeSlug = element.getAttribute('data-store-slug');
    const storeName = element.getAttribute('data-store-name');
    const retailerSlug = element.getAttribute('data-retailer-slug');
    const retailerName = element.getAttribute('data-retailer-name');
    const promotionID = element.getAttribute('data-promotion-id');
    const manufacturerId = element.getAttribute('data-manufacturer-id');
    const showButton = element.getAttribute('data-show-button') || false;
    const longText = element.getAttribute('data-long-text') || false;
    const isMobile = element.getAttribute('data-is-mobile') || false;
    const locationPage = element.getAttribute('data-location-page') || '';
    const pageRegion = element.getAttribute('data-page-region') || '';
    const source = element.getAttribute('data-source') || '';
    const promoType = element.getAttribute('data-promo-type') || '';
    const showPromotionTypeText = element.getAttribute('data-show-promotion-type-text') || false;

    const root = createRoot(element);

    root.render(
      <CashbackPromoButton
        storeSlug={storeSlug}
        storeName={storeName}
        retailerSlug={retailerSlug}
        retailerName={retailerName}
        promotionID={promotionID ? parseInt(promotionID, 10) : null}
        manufacturerId={manufacturerId || undefined}
        locationPage={locationPage}
        source={source}
        promoType={promoType}
        pageRegion={pageRegion}
        showButton={showButton ? showButton === 'true' : false}
        longText={longText ? longText === 'true' : false}
        isMobile={isMobile ? isMobile === 'true' : false}
        showPromotionTypeText={showPromotionTypeText ? showPromotionTypeText === 'true' : false}
      />,
    );
  });
}
