/*
  Add on this enum the feature flags provided by the backend.
*/
export enum FeatureFlags {
  COLLECT_MORE_PHONE_NUMBERS_V1 = 'COLLECT_MORE_PHONE_NUMBERS_V1',
  RETAILER_DASHBOARD_REQUEST_SETTINGS = 'RETAILER_DASHBOARD_REQUEST_SETTINGS',
  RETAILER_DASHBOARD_DISPLAY_SETTINGS = 'RETAILER_DASHBOARD_DISPLAY_SETTINGS',
  RETAILER_DASHBOARD_PRODUCT_LOOKUP = 'RETAILER_DASHBOARD_PRODUCT_LOOKUP',
  RETAILER_DASHBOARD_API_SETTINGS = 'RETAILER_DASHBOARD_API_SETTINGS',
  HEADER_SHOW_POPULAR_COMPARISONS = 'HEADER_SHOW_POPULAR_COMPARISONS',
}

export const FEATURE_FLAGS_URL = '/feature-flags/get-feature-flag/';

/*
  PostHog Feature Flags
*/
export enum PostHogFeatureFlags {
  CTA_DEAL_BTN = 'cta-deal-btn',
  PROFILE_PAGE_SIDEBAR_WIDTH = 'profile-page-sidebar-width',
  PROMO_BOX_DESIGN = 'promo-box-design',
  PROMO_SEND_DEALS = 'promo-send-deals',
}

export enum PostHogFeatureFlagVariantKeys {
  CONTROL = 'control',
  TEST = 'test',
}

export enum PostHogExperimentEvents {
  PROMO_BUTTON_CLICKED = 'promo-button-clicked',
  PRODUCT_DISCOUNT_BOX_CLICKED = 'product-discount-box-clicked',
  PROMO_SEND_DEALS_CLICKED = 'promo-send-deals-clicked',
}
