import React, { memo, useState, ReactNode } from 'react';
import { Accordion as BSAccordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import styles from './Accordion.module.scss';
import SmoothChevronIcon from '../Icons/SmoothChevron';
import { getClassNames } from '../../../utils/styles';

export const ACCORDION_TEST_ID = 'accordion';

export type AccordionItem = {
  collapseContainerClassname?: string;
  eventKey: string;
  title: string;
  description?: string | ReactNode;
  imgSrc?: string;
  element?: JSX.Element | JSX.Element[];
  TriggerComponent?: any;
  extraTriggerProps?: any;
};

type AccordionProps = {
  defaultActiveKey?: string;
  children: AccordionItem[];
  smoothChevronIcon?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
};

const Accordion: React.FC<AccordionProps> = ({
  defaultActiveKey,
  children,
  smoothChevronIcon = false,
  onClick,
}: AccordionProps) => {
  const [selectedKey, setSelectedKey] = useState<string | null | undefined>(defaultActiveKey);

  const handleSelect = (key: string | null): void => {
    setSelectedKey(key);
  };

  const handleCollapse = (e: React.MouseEvent<HTMLElement>): void => {
    if (onClick) onClick(e);
  };

  const renderBaseHeader = (title: string, description?: string | ReactNode, imgSrc?: string): ReactNode => {
    return (
      <>
        {imgSrc ? <img alt={title} src={imgSrc} /> : null}
        <div className="card-header-container">
          <div className="card-header-title">{title}</div>
          {description ? <div className="card-header-description">{description}</div> : null}
        </div>
      </>
    );
  };

  return (
    <BSAccordion
      data-testid={ACCORDION_TEST_ID}
      className={getClassNames('accordion', styles)}
      defaultActiveKey={defaultActiveKey}
      onSelect={handleSelect}
    >
      {children &&
        children.length > 0 &&
        children.map(
          (
            {
              collapseContainerClassname,
              eventKey,
              title,
              description,
              imgSrc,
              element,
              TriggerComponent,
              extraTriggerProps,
            }: AccordionItem,
            idx: number,
          ) => (
            <Card key={eventKey} className={getClassNames('card', styles)}>
              {TriggerComponent ? (
                /* eslint-disable-next-line */
                <TriggerComponent eventKey={eventKey} onClick={handleCollapse} {...extraTriggerProps}>
                  {renderBaseHeader(title, description, imgSrc)}
                </TriggerComponent>
              ) : (
                <BSAccordion.Toggle
                  as={Card.Header}
                  className={getClassNames('card-header', styles)}
                  eventKey={eventKey}
                  onClick={handleCollapse}
                >
                  <>
                    {renderBaseHeader(title, description, imgSrc)}
                    {smoothChevronIcon ? (
                      <SmoothChevronIcon rotate={selectedKey === eventKey ? 90 : 180} />
                    ) : (
                      <FontAwesomeIcon icon={selectedKey === eventKey ? faChevronUp : faChevronDown} />
                    )}
                  </>
                </BSAccordion.Toggle>
              )}
              <BSAccordion.Collapse data-testid={`${ACCORDION_TEST_ID}-collapse-${idx}`} eventKey={eventKey}>
                <Card.Body className={`${getClassNames('card-body', styles)} ${collapseContainerClassname}`}>
                  {element}
                </Card.Body>
              </BSAccordion.Collapse>
            </Card>
          ),
        )}
    </BSAccordion>
  );
};

export default memo(Accordion);
