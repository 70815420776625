import React, { FC } from 'react';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import { EMPTY_EVENT_PRODUCT } from 'datalayer-service/src/event-objects';
import Row from 'react-bootstrap/Row';
import { Deal, DealItem, RetailerDeal } from '../../../types/deal';
import { NewPromotion, PromotionType } from '../../../types/promotion';
import { Brand } from '../../../types/brands';
import { getProductEventDataWithMattressHierarchy } from '../../../utils/dataLayer';
import SmallArrowDoodleIcon from '../../atoms/Icons/SmallArrowDoodle';
import Accordion, { AccordionItem } from '../../atoms/Accordion';
import Button from '../../atoms/Button';
import PromoChip from '../../atoms/PromoChip';
import PromoModalBodyPromoBoxes from './BodyPromoBoxes';
import { getPromotionsFromDeal, openCashbackOutboundUrl } from '../../../utils/promos';

type PromoModalBodyRetailersPromotionProps = {
  pageRegion: PageRegion;
  deal?: Deal;
  onGetDeal(
    retailer: RetailerDeal,
    rebatePromotion?: NewPromotion,
    generalPromotion?: NewPromotion,
    dealHash?: string,
  ): void;
};

export const getRetailersPromotionAccordionTitle = (item: DealItem): string =>
  item.main_promotion_type === PromotionType.AFFILIATE_REBATE
    ? `Shop online at ${item.brand.name}`
    : `Shop and try ${item.brand.name} at ${item.retailer.name}`;

const PromoModalBodyRetailersPromotion: FC<PromoModalBodyRetailersPromotionProps> = ({
  pageRegion,
  deal,
  onGetDeal,
}) => {
  return (
    <div className="shared-ui__promo-modal__body-retailers">
      <div className="shared-ui__promo-modal__body-retailers-choose-retailer">
        Choose any retailer.
        <SmallArrowDoodleIcon />
      </div>

      <Accordion>
        {
          deal?.deals.map((item): AccordionItem => {
            const { onlinePromotion, rebatePromotion, generalPromotion } = getPromotionsFromDeal(item.promotions);

            return {
              eventKey: item.retailer.slug,
              title: getRetailersPromotionAccordionTitle(item),
              description:
                item.main_promotion_type === PromotionType.AFFILIATE_REBATE ||
                item.main_promotion_type === PromotionType.LOCAL_REBATE ? (
                  <PromoChip label={`${item.description} Back`} />
                ) : (
                  ''
                ),
              imgSrc: item.retailer.logo,
              element: (
                <>
                  <PromoModalBodyPromoBoxes
                    pageRegion={pageRegion}
                    dealItem={item}
                    onlinePromotion={onlinePromotion}
                    rebatePromotion={rebatePromotion}
                    generalPromotion={generalPromotion}
                    toggleable={false}
                  />
                  {rebatePromotion && (
                    <Row className="justify-content-center">
                      <Button
                        ariaLabel={`Get Deal for ${item.retailer.name}`}
                        color="dark-blue"
                        variant="secondary"
                        ctaData={{
                          category: CTAClickCategory.CASHBACK_PROMOTION,
                          level: CTAClickLevel.SECONDARY,
                          url: '/',
                          pageRegion,
                          product: deal.brand
                            ? getProductEventDataWithMattressHierarchy(deal.brand as Brand)
                            : EMPTY_EVENT_PRODUCT,
                        }}
                        onClick={(): void => {
                          if (rebatePromotion.promotion_type === PromotionType.LOCAL_REBATE) {
                            onGetDeal(item.retailer, rebatePromotion, onlinePromotion || generalPromotion, item.hash);
                          } else openCashbackOutboundUrl(deal.brand.id, rebatePromotion, onlinePromotion);
                        }}
                      >
                        How to Get this Deal
                      </Button>
                    </Row>
                  )}
                </>
              ),
            };
          }) as AccordionItem[]
        }
      </Accordion>
    </div>
  );
};

export default PromoModalBodyRetailersPromotion;
