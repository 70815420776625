import React, { FC, HTMLAttributes, HTMLInputTypeAttribute, memo, useState } from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Form, FormControlProps, InputGroup } from 'react-bootstrap';

type InputType = HTMLInputTypeAttribute | 'currency';

export interface InputProps extends FormControlProps {
  formControlClassname?: string;
  formGroupClassname?: string;
  inputGroupClassname?: string;
  name: string;
  placeholder?: string;
  type?: InputType;
  role?: HTMLAttributes<HTMLInputElement>['role'];
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  value: string | number;
  icon?: string | JSX.Element | JSX.Element[];
  iconGroupClassName?: string;
  prependIcon?: boolean;
  required?: boolean;
  minLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number | undefined;
  autoComplete?: string;
  autoFocus?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  lengthLimitReminder?: number;
}

const Input: FC<InputProps> = ({
  className,
  formControlClassname = '',
  formGroupClassname = '',
  inputGroupClassname = '',
  name,
  placeholder,
  type = 'text',
  value,
  size = 'sm',
  icon,
  iconGroupClassName = '',
  prependIcon = true, // only use if icon prop is passed to component.
  required = false,
  disabled = false,
  readOnly = false,
  minLength,
  onChange,
  maxLength = undefined,
  autoComplete = 'on',
  autoFocus,
  isInvalid = undefined,
  onFocus = undefined,
  onBlur = undefined,
  lengthLimitReminder = 0,
}) => {
  const [titleLength, setTitleLength] = useState<number>(maxLength || 0);
  const cls = classnames(className, {
    disabled,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (maxLength) setTitleLength(maxLength - e.target.value.length);
    if (onChange) onChange(e);
  };

  const getFormControl = (): JSX.Element => (
    <Form.Control
      className={`${cls} ${formControlClassname}`}
      id={name}
      name={name}
      as="input"
      placeholder={placeholder}
      type={type}
      size={size}
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      required={required}
      onChange={handleChange}
      maxLength={maxLength}
      autoComplete={autoComplete}
      isInvalid={isInvalid}
      onBlur={onBlur}
      onFocus={onFocus}
      minLength={minLength}
      autoFocus={autoFocus}
    />
  );

  return (
    <>
      {isEmpty(icon) && getFormControl()}
      {!isEmpty(icon) && (
        <Form.Group className={`${formGroupClassname}  ${readOnly ? 'readonly' : ''}`}>
          <InputGroup className={inputGroupClassname}>
            {prependIcon && (
              <InputGroup.Prepend>
                <InputGroup.Text className={iconGroupClassName}>{icon}</InputGroup.Text>
              </InputGroup.Prepend>
            )}
            {getFormControl()}
            {!prependIcon && (
              <InputGroup.Append>
                <InputGroup.Text className={iconGroupClassName}>{icon}</InputGroup.Text>
              </InputGroup.Append>
            )}
          </InputGroup>
        </Form.Group>
      )}
      {maxLength && titleLength < lengthLimitReminder ? (
        <div className="title-remaining-chars">
          <b>{titleLength}</b> {titleLength === 1 ? 'character' : 'characters'} left
        </div>
      ) : null}
    </>
  );
};

export default memo(Input);
