import React, { FC, memo, useEffect } from 'react';
import isNumber from 'lodash/isNumber';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaOptionsType } from 'embla-carousel';
import cls from 'classnames';
import { useDotButton } from './hooks/useDotButton';
import { usePrevNextButtons } from './hooks/usePrevNextButtons';
import styles from './MultiCarousel.module.scss';
import SmoothChevronIcon from '../../atoms/Icons/SmoothChevron';

export interface PrevNextButtonProps {
  onClick: () => void;
  disabled: boolean;
  isNext: boolean;
  className?: string;
}

export interface DotButtonProps {
  onClick: (index: number) => void;
  selected: boolean;
}

const DefaultNextPrevButtons: FC<PrevNextButtonProps> = ({ isNext, disabled, onClick, className = '' }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cls(styles.prevNextButton, { [styles.nextButton]: isNext, [styles.prevButton]: !isNext }, className)}
      type="button"
      aria-label={`${isNext ? 'Next' : 'Previous'} items`}
    >
      <SmoothChevronIcon rotate={isNext ? 180 : 0} />
    </button>
  );
};

export interface CarouselProps extends EmblaOptionsType {
  currentSlide?: number;
  children: JSX.Element[];
  className?: string;
  slidesClassname?: string;
  arrowButtonsClassname?: string;
  showArrows?: boolean;
  showDots?: boolean;
  NextButton?: FC<PrevNextButtonProps>;
  PrevButton?: FC<PrevNextButtonProps>;
  DotButton?: FC<DotButtonProps>;
}

const Carousel: FC<CarouselProps> = ({
  currentSlide,
  className,
  slidesClassname,
  arrowButtonsClassname,
  children,
  NextButton,
  PrevButton,
  DotButton,
  showArrows = true,
  showDots = false,
  ...carouselOptions
}) => {
  const [carouselRef, emblaApi] = useEmblaCarousel({ loop: false, dragFree: true, ...carouselOptions });
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  useEffect(() => {
    if (isNumber(currentSlide) && currentSlide! >= 0) {
      emblaApi?.scrollTo(currentSlide);
    }
  }, [currentSlide]);

  const hasControls = showArrows || showDots;

  if (!children.length) return null;

  return (
    <div className="embla" ref={carouselRef}>
      <ul className={`embla__container ${className || ''}`}>
        {children.map((child) => (
          <li className={`embla__slide ${slidesClassname || ''}`}>{child}</li>
        ))}
      </ul>
      {hasControls ? (
        <div className="embla__controls">
          {showArrows ? (
            <>
              {PrevButton ? (
                <PrevButton
                  onClick={onPrevButtonClick}
                  disabled={prevBtnDisabled}
                  className={arrowButtonsClassname}
                  isNext={false}
                />
              ) : (
                <DefaultNextPrevButtons
                  onClick={onPrevButtonClick}
                  disabled={prevBtnDisabled}
                  className={arrowButtonsClassname}
                  isNext={false}
                />
              )}
              {NextButton ? (
                <NextButton
                  onClick={onNextButtonClick}
                  disabled={nextBtnDisabled}
                  className={arrowButtonsClassname}
                  isNext
                />
              ) : (
                <DefaultNextPrevButtons
                  onClick={onNextButtonClick}
                  disabled={nextBtnDisabled}
                  className={arrowButtonsClassname}
                  isNext
                />
              )}
            </>
          ) : null}

          {showDots && DotButton
            ? scrollSnaps.map((_, index) => <DotButton onClick={onDotButtonClick} selected={index === selectedIndex} />)
            : null}
        </div>
      ) : null}
    </div>
  );
};

export default memo(Carousel);
